<template>
  <footer class="footer bg-light-green">
    <p class="copyright">Promobuzz © {{ year }}</p>
    <a href="mailto:Contact@promobuzz.com" class="email">Contact@promobuzz.com</a>
  </footer>
</template>
<script setup>
import { computed } from 'vue'
const year = computed(() => new Date().getFullYear())
</script>
<style lang="sass" scoped>
@import ../styles/variables
.footer
  padding: 105px 128px 85px 128px
  box-shadow: 0px -33px 0px 0px $black
  font-size: 22px
  color: $black

  .copyright  
    font-weight: 800
    margin-bottom: 30px

  .email
    font-weight: 500
    text-decoration: none
    color: $black
    display: flex
    align-items: center

    &::before
      content: ''
      display: inline-block
      width: 42px
      height: 33px
      margin-right: 18px
      background: 
        image: url(@/assets/email.svg)
        size: 100%
        repeat: no-repeat
        position-y: center

  @media screen and (max-width: 1536px)
    padding: 105px 96px 85px 96px

  @media screen and (max-width: 1200px)
    padding: 80px 42px 55px 42px

  @media screen and (max-width: 767px)
    padding: 40px 24px 55px 24px
    font-size: 19px

    .email::before
      min-width: 24px
      margin-right: 15px

</style>