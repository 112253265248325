<template>
  <header class="header container">
    <div class="desktop-nav-wrap">
      <nav class="desktop-nav">
        <router-link :to="{path: '/', hash: '#about'}">About</router-link>
        <router-link :to="{path: '/', hash: '#packages'}">Packages</router-link>
        <router-link :to="{path: '/', hash: '#use-cases'}">Use Cases</router-link>
      </nav>
    </div>
    <a href="/" class="logo">
      <img alt="PROMOBUZZ" src="../assets/logo.svg" />
    </a>
    <div class="questions">
      <router-link :to="{path: '/', hash: '#questions'}">Do you have questions?</router-link>
    </div>
    <button 
      :class="['trigger', {close: menuOpen}]" 
      @click="toggleMenu"
      id="menuTrigger">
      <span></span>
      <span></span>
    </button>
    <Transition name="fade">
      <nav 
        v-if="menuOpen" 
        ref="menuRef"
        class="menu"
        v-click-outside:menuTrigger="menuFocusOut">
        <router-link :to="{path: '/', hash: '#about'}">About</router-link>
        <router-link :to="{path: '/', hash: '#packages'}">Packages</router-link>
        <router-link :to="{path: '/', hash: '#use-cases'}">Use Cases</router-link>
        <router-link :to="{path: '/', hash: '#questions'}"><b>Do you have questions?</b></router-link>
      </nav>
    </Transition>
  </header>
</template>
<script setup>
import { ref } from 'vue'
const menuOpen = ref(false)
const menuRef = ref()

const vClickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      const excludedComponent = document.getElementById(binding.arg)
      if (!(el === event.target || el.contains(event.target)) &&
        !(excludedComponent && (event.target ==- excludedComponent || excludedComponent.contains(event.target)))) {
        binding.value(event)
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
    document.addEventListener('scroll', el.clickOutsideEvent)
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent)
    document.removeEventListener('scroll', el.clickOutsideEvent)
  }
}

const toggleMenu = () => menuOpen.value = !menuOpen.value
const menuFocusOut = () => menuOpen.value = false
</script>
<style lang="sass" scoped>
@import ../styles/variables

// transition classes
.fade-enter-active,
.fade-leave-active
  transition: all .2s ease

.fade-enter-from,
.fade-leave-to 
  opacity: 0
  transform: translateY(-100px)

// end transition classes

.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 50px 0 0
  z-index: 3

  .logo
    margin: 0 auto

  .desktop-nav-wrap,
  .questions
    flex: 1

  .desktop-nav
    a
      margin-right: 50px
      position: relative
      font-size: 22px
      color: $black
      text-decoration: none
      font-weight: 600

      &::before
        content: ''
        position: absolute
        height: 5px
        width: 5px
        bottom: -9px
        left: 0
        background-color: $light-grey
        transition: all .4s ease

      &:hover,
      &.active
        &::before
          width: 100%
          background-color: $yellow
          
      &:nth-child(2)
        &:hover,
        &.active
          &::before
            background-color: $light-green
      
      &:nth-child(3)
        &:hover,
        &.active
          &::before
            background-color: $light-yellow

        
  .questions
    text-align: right

    a
      font-size: 22px
      font-weight: 800
      color: $black
      text-decoration: none

  .trigger, .menu
    display: none

  @media screen and (max-width: 1536px)
    .logo img
      width: 250px

    .desktop-nav,
    .questions
      a
        font-size: 18px

  @media screen and (max-width: 1200px)
    padding: 25px 0 0

    .logo img
      width: 150px

    .desktop-nav,
    .questions
      a
        font-size: 16px

  @media screen and (max-width: 1024px)
    padding-top: 20px
    position: fixed
    width: 100vw
    top: 0
    left: 0
    background: #fff
    padding-bottom: 20px
    .trigger, .menu
      display: block

    .desktop-nav-wrap, .questions
      display: none

    .logo
      margin-left: 0
      z-index: 3

    .trigger 
      height: 25px
      width: 31px
      position: relative
      background: transparent
      box-shadow: none
      border: none
      z-index: 3

      span
        width: 31px
        height: 4px
        left: 0
        background: $black
        position: absolute
        transition: all .2s ease

        &:first-child
          top: 6px

        &:last-child
          top: 16px

      &.close
        span
          top: 10px

          &:first-child
            transform: rotate(45deg)

          &:last-child
          transform: rotate(-45deg)

    .menu
      position: fixed
      width: 100vw
      background: #fff
      top: 0
      left: 0
      padding: 128px 42px 55px
      display: flex
      flex-direction: column
      
      z-index: 2

      a
        font-size: 22px
        color: $black
        font-weight: 500
        margin-bottom: 35px
        text-decoration: none

        b
          font-weight: 800

  @media screen and (max-width: 767px)
    .menu
      padding: 128px 24px 55px
</style>