<template>
  <div class="package" :class="`bg-${props.color}`">
    <div class="title">
      {{ title }}
    </div>


      <perfect-scrollbar class="scrollable">
        <slot></slot>
      </perfect-scrollbar>


    <div class="price">
      Price: {{ price }}
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  title: String,
  price: String,
  color: 'green' | 'light-green' | 'yellow' | 'light-yellow'
})
</script>
<style lang="sass" scoped>
@import ../styles/variables

.package
  border-radius: 65px
  border: 1px solid $black
  height: 800px
  max-height: 800px
  color: $black
  line-height: 1.6
  font-size: 30px
  font-weight: 500
  display: flex
  flex-direction: column
  padding: 35px 0

  .title
    font-size: 40px
    margin-bottom: 40px
    font-weight: 800
    padding-left: 25px

  .price
    font-weight: 800
    margin-top: auto
    text-align: center

  .scrollable
    overflow: auto
    padding: 0 25px
    margin-bottom: 45px

  @media screen and (max-width: 1536px)
    font-size: 24px
    height: 600px
    max-height: 600px

  @media screen and (max-width: 1200px)
    font-size: 20px

  @media screen and (max-width: 1024px)
    font-size: 16px
    height: 500px
    max-height: 500px
    .title
      font-size: 32px
      margin-bottom: 32px
    
</style>