<template>
  <span ref="rootEl" class="underline-wrap">
    <slot></slot>
    <div 
      ref="lineEl" 
      class="underline-line"
      :class="[props.variant, 'from-'+props.from, 'color-'+props.color, 'bg-'+props.color]"
      >
    </div>
  </span>
</template>
<script setup>
import { ref, onMounted  } from 'vue'

const props = defineProps({
  from: 'left' | 'right',
  to: 'start' | 'end',
  variant: 'default' | 'pointy',
  color: 'green' | 'light-green' | 'yellow' | 'light-yellow'
})

const rootEl = ref()
const lineEl = ref()

onMounted(() => {
  const rect = rootEl.value.getBoundingClientRect()

  if (props.from === 'left') {
    lineEl.value.style.width = rect.right + 'px'

    if (props.to === 'start') {
      lineEl.value.style.right = '100%'
    }

    if (props.to === 'end') {
      lineEl.value.style.right = '0'
    }
  }

  if (props.from === 'right') {
    lineEl.value.style.width = `calc(100vw - ${rect.right}px + ${rootEl.value.offsetWidth}px)`

    if (props.to === 'start') {
      lineEl.value.style.left = '0'
    }

    if (props.to === 'end') {
      lineEl.value.style.left = '100%'
    }

  }
})
</script>
<style lang="sass" scoped>
@import ../styles/variables
.underline
  &-wrap
    position: relative

  &-line
    position: absolute
    height: 14px
    background-color: $green
    top: 1.4em
    z-index: -1

    &.pointy
      &::before
        content: ''
        position: absolute
        width: 30px
        height: 28px
        background: #fff
        border: 4px solid
        top: -7px
        border-top-left-radius: 8px 12px
        border-bottom-left-radius: 8px 12px
        border-top-right-radius: 8px 12px
        border-bottom-right-radius: 8px 12px
      &.from-left
        &::before
          right: 0
      &.from-right
        &::before
          left: 0

  @media screen and (max-width: 1200px)
    &-line:not(.pointy)
      height: 8px

  @media screen and (max-width: 767px)
    &-line.pointy
      top: 1.6em

</style>